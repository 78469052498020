.about__sub__title {
    color: #595959;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
}

.about__grid__section {
    border-radius: 20px;
    background: rgba(224, 224, 224, 0.26);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    padding-top: 1em;
    padding-bottom: 2em;
}

.about__sub__title2 {
    font-size: 32px;
    color: #000;
    font-weight: bolder;
    margin-top: 0.6em;
    margin-bottom: 0;
}
.about__sub__title2__span {
    color: #e44540;
}
.about__sub__title2__paragraph {
    color: #595959;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.imgStyle1 {
    width: 170px;
    height: 60px;
    object-fit: contain;
}

.image__side__style {
    height: 400px;
    position: relative;
}
.img__side__style {
    max-width: 100%;
    width: 100%;
    height: 430px;
    vertical-align: middle;
    object-fit: cover;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}
.img__side__style__inner {
    position: absolute;
    bottom: -2.5em;
    right: 4em;
    border-radius: 15px;
    border: 3px dotted #e44540;
    right: 100px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.81);
}
.badge__about {
    width: 73px;
    height: 73px;
    object-fit: contain;
    /* margin-top: 2em; */
}
.img__side__style__inner__p {
    color: #e44540;
    font-size: 28.58px;
    font-weight: 400;
    margin-top: 1px;
    margin-bottom: -0.5em;
}

.img__side__style__inner___p {
    color: #000;

    font-size: 18.58px;
}
.second__about__grid {
    background-image: url("../assets/movaex_bike_front.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 350px;
    width: 100%;
    background-attachment: fixed;
    position: relative;
}

.second__about__grid:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0.6;
    content: "";
}

.second__about__sub__title,
.second__about__sub__paragraph {
    color: #fff;
    mix-blend-mode: lighten;
}

.second__about__sub__title {
    font-size: 24px;
    font-weight: 700;
}

.second__about__sub__paragraph {
    font-size: 17px;
    line-height: 1.8;
}

@media only screen and (max-width: 767px) {
    .img__side__style {
        max-width: 100%;
        width: 100%;
        height: 260px;
        vertical-align: middle;
        object-fit: cover;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }
    .image__side__style {
        height: 300px;
        position: relative;
    }

    .about__sub__title2 {
        font-size: 32px;
        color: #000;
        font-weight: bolder;
        margin-top: 0.6em;
        margin-bottom: 0;
    }

    .img__side__style__inner {
        position: absolute;
        bottom: 0.2px;
        /* right: 4em; */
        border-radius: 15px;
        border: 3px dotted #e44540;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.81);
    }

    .img__side__style__inner__p {
        color: #e44540;
        font-size: 20.58px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: -0.5em;
    }
    .img__side__style__inner___p {
        color: #000;

        font-size: 16.58px;
    }

    .about__sub__title {
        color: #595959;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
    }

    .badge__about {
        width: 63px;
        height: 63px;
        object-fit: contain;
    }

    .about__grid__section {
        padding-top: 0.5em;
        padding-bottom: 1em;
    }
}
