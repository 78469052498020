.partner__grid {
  margin-top: 1em;
}
.partner__image {
  max-width: 100%;
  width: 170px;
  height: 170px;
  vertical-align: middle;
  object-fit: contain;
}
.partner__paragraph {
  font-size: 16px;
  color: #303030;
  line-height: 1.9;
}
