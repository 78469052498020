.faqs__grid__section {
  border-radius: 20px;
  background: rgba(224, 224, 224, 0.26);
  padding-top: 1em;
  padding-bottom: 2em;
}

.faqs__sub__paragraph {
  color: #595959;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.faqs__phone__style {
  width: 348px;
  height: 100%;
  object-fit: contain;
}
.____faqs {
  color: #000 !important;
}

.contact____link {
  color: #e44540;
  text-decoration: none;
  font-weight: bold;
}

.p-accordion-header-text {
  line-height: 1;
  color: #595959;
}

@media (max-width: 767px) {
  .faqs__sub__paragraph {
    font-size: 12px;
  }

  .faqs__phone__style {
    width: 100%;
    height: 400px;
    object-fit: contain;
  }
}
