.our__app__main {
  margin-top: 5rem;
}

.our__app__image {
  max-width: 100%;
  width: 100%;
  height: 380px;
  object-fit: contain;
}
.side__article__h6 {
  font-size: 28px;
  font-weight: bold;
  color: #e44540;
}
.side__article__p {
  font-size: medium;
  line-height: 2;
  color: #595959;
}

@media (max-width: 767px) {
  .our__app__main {
    margin-top: 2rem;
  }
  .side__article__h6 {
    margin-top: 0;
  }
  .side__article__h6 {
    font-size: 25px;
    font-weight: bold;
    color: #e44540;
  }
}
