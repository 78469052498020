.hero__section__header {
  background-image: url('../assets/movaex_bike_back.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 350px;
  width: 100%;
  background-attachment: fixed;
  position: relative;
}

.hero__section__header:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #131313;
  opacity: 0.7;
  content: '';
}
.Hero__title {
  color: #e44540;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .Hero__title {
    font-size: 32px;
  }

  .hero__section__header {
    background-image: url('../assets/movaex_bike_front.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 250px;
    width: 100%;
    background-attachment: fixed;
    position: relative;
  }
}
