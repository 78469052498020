.sectionStyle5 {
  border-radius: 22px;
  background-color: #e04540;
  position: relative;
  box-shadow: 0px 4px 140px 0px rgba(0, 0, 0, 0.05);
  padding: 28px;
}

.headerStylew5 {
  color: #fff480;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 39.68px;
}

.paragraph5 {
  color: #fefefe;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%;
}

.imageStyle5 {
  width: 120px;
  object-fit: contain;
}

.mainStyle5 {
  position: relative;
  border-radius: 22px;
  margin-top: 7em !important;
}

.mainStyle5::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../assets/bgm.png');
  background-size: cover;
  background-position: center;
  opacity: 0.6;
  z-index: -1;
}

.movaImageStyle5 {
  width: 300px;
  height: 450px;
  object-fit: contain;
  position: absolute;
  bottom: -7px;
}

.circleImageStyle5 {
  position: absolute;
  left: 90px;
}

.smallText5 {
  font-size: 14px;
  color: #fff;
}

@media (max-width: 767px) {
  .sectionStyle5 {
    padding: 14px;
    margin-bottom: 17em;
  }

  .movaImageStyle5 {
    bottom: 0;
    width: 100% !important;
  }

  .circleImageStyle5 {
    bottom: -2em;
  }

  .mainStyle5 {
    border-radius: 22px;
    background-image: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgba(238, 238, 238, 0.75);
    position: relative;
    margin-top: 2em !important;
  }
}
