.page404 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.page404__container {
  display: flex;
  align-items: center;
}

.page404__content {
  text-align: center;
  padding: 20px;
}

.page404___h1 {
  font-size: 1.5em;
  color: #e44540;
}

.page404___p {
  font-size: 0.6em;
  margin-bottom: 15px;
}

.page404___a {
  text-decoration: none;
  color: #e44540;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: medium;
  font-size: large;
}
