.company__objective__title__h2 {
  color: #e44540;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  margin-top: 2em;
}

.company__objective___paragraph__h5 {
  font-size: 18px;
  line-height: 20px;
}

.company__objective___paragraph__li {
  color: #000;

  font-size: 15px;
  line-height: 28px;
}

@media only screen and (max-width: 768px) {
  .company__objective__title__h2 {
    font-size: 28px;
    margin-top: 2em;
  }
}
