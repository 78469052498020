.map___style {
  width: 100%;
  height: 600px;
}
.contact__title {
  color: #e44540;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contact__grid__section {
  border: 0.5px solid #ccc;
  border-radius: 10px;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.contact______link {
  color: #e44540 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

.contact__grid__section:hover {
  border-bottom: 3px solid #e44540;
}

.contact__h6 {
  color: #000;
  font-size: 17px;
  font-weight: 600;
}
.contact__paragrph {
  color: #303030;
  font-family: Ambit;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.last__p {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  color: #e44540;
  cursor: pointer;
}
a.span {
  color: #e44540;
  text-decoration: none;
}
.contact__icon {
  border: 2px dotted #ffca02;
  border-radius: 10px;
  padding-bottom: 2px;
}

.form__section {
  margin-top: 4em;
}
.___form {
  background: rgba(224, 224, 224, 0.26);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  padding: 3em;
}

.contact___p {
  color: #303030;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.___btn {
  background-color: #e44540;
  color: #fff;
  outline: none;
}

.contact___paragraph {
  color: #595959;
  font-size: medium;
  line-height: 23px;
}

.contact___paragraph__h5 {
  font-size: 18px;
  line-height: 28px;
  font-size: bold;
}

.contact___paragraph__li {
  color: #000;

  font-size: 17px;
  line-height: 28px;
}
.contact__span {
  color: #e44540;
  font-size: bold;
}

.a___a {
  color: #e44540;
  text-decoration: none;
}
.contact__me__h2 {
  font-weight: bold;
  font-size: xx-large;
}

/* ADS style starts here  */

.ads__social__image {
  max-width: 100%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
}



/* Media query for small devices (max-width: 767px) */
@media only screen and (max-width: 767px) {
  .map___style {
    height: 300px;
  }
  .___form {
    background: rgba(224, 224, 224, 0.26);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 0.9em;
  }
}
