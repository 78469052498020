/* Background Style */
.bg6 {
  width: 60px;
  height: 60px;
  background-color: #fff480;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  position: relative;
}

/* Image Style */
.imageStyle6 {
  width: 20px;
  height: 20px;
  object-fit: contain;
  overflow: hidden;
}

/* Title Style */
.titleStyle6 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  /* font-family: 'Ambit'; */
  font-weight: 600;
  line-height: normal;
}

/* Paragraph Style */
.paragraphStyle6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #656565;
}

/* Header Style */
.headerStyle6 {
  color: #000;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 5em;
}

/* Badge Image Style */
.badgeImageStyle6 {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  overflow: visible;
  background-color: none;
  z-index: 1;
  animation: gentleShake 5s infinite;
}

@keyframes gentleShake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-2px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(2px);
  }
}

/* Small devices */
@media (max-width: 767px) {
  .headerStyle6 {
    font-size: 28px;
    margin-top: 2em;
  }
  .titleStyle6 {
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-family: 'Ambit';
    font-weight: 600;
    line-height: normal;
  }
}
