.privacy____sub__header {
  color: #595959;
  font-size: medium;
  font-weight: 400;
  font-style: italic;
}

.privacy___paragraph {
  color: #595959;
  font-size: 17px;
  line-height: 23px;
}

.privacy___paragraph__h5 {
  color: #595959;
  font-size: 16px;
  line-height: 28px;
}

.privacy__header__h4 {
  font-size: 19px;
}

.privacy___paragraph__li {
  color: #000;

  font-size: 17px;
  line-height: 28px;
}
.privacy__span {
  color: #e44540;
}

@media (max-width: 767px) {
}
