.terms___paragraph__h6 {
  color: #000;
  font-size: 19px;
  line-height: 28px;
}
.terms__inner__h6 {
  color: #393939;
  font-size: 18px;
  line-height: 28px;
}
.inner__bold {
  font-weight: bold;
}
.terms___p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.terms___address {
  color: #595959;
  font-size: 17px;
  font-style: italic;
}

.terms___header__p {
  color: #595959;
  font-size: 15px;
  font-weight: bold;
  margin-top: -1rem;
}

.terms___paragraph__li {
  color: #595959;

  font-size: 14px;
  line-height: 28px;
}
.______span {
  color: #e44540;
  font-weight: bold;
}
.a_____style {
  color: #e44540;
  text-decoration: none;
}
.section__one__class {
  color: #e44540;
  font-size: 15px;
  line-height: 28px;
}

.section__one__class__a {
  text-decoration: none;
  color: #e44540;
}
