@font-face {
  font-family: 'Ambit';
  src: local('Ambit-Regular'),
    url('../src/fonts/Ambit/Ambit-Regular.ttf') format('truetype');
}
@import '~font-awesome/css/font-awesome.min.css';

h1,
h2,
h3,
h4,
h5,
h6,
small,
span,
p,
div,
Accordion,
AccordionTab {
  font-family: 'Ambit';
}

body {
  margin: 0;
  font-family: 'Ambit';
  overflow-x: hidden;
  font-size: 32px;
  color: #000;
}

code {
  font-family: 'Ambit';
}
