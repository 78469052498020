/* Header Style */
.header__3 {
  color: #000;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 39rem;
}

/* Paragraph Style */
.paragraph3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  color: #000000;
}

/* Subsection Style */
.subSection3 {
  border-radius: 40px;
  background: rgba(224, 224, 224, 0.26);
  padding: 3em;
}

/* Card Style */
.card3 {
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 4px 140px 0px rgba(0, 0, 0, 0.05);
  padding: 1em !important;
  width: 100%;
}

/* Card Title Style */
.cardTitle3 {
  color: #e44540;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 50px;
}

/* Card Paragraph Style */
.cardParagraph3 {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  color: #595959;
}

/* Image Style */
.image3 {
  top: -50px;
}

/* Image Container Style */
.imageContainer3 {
  border-radius: 50%;
  border: 13px solid #fff;
  width: 100%;
}

/* Circle Image Style */
.circleImage3 {
  position: absolute;
  bottom: -50px;
  left: 200px;
  width: 93px;
  height: 94px;
}

/* Small devices */
@media (max-width: 767px) {
  .header__3 {
    font-size: 30px;
    margin-top: -2em;
    margin-bottom: 1.3em;
  }

  .paragraph3 {
    font-size: 16px;
    line-height: 28px;
  }

  .cardTitle3 {
    font-size: 20px;
    margin-top: 2.7rem;
  }

  .cardParagraph3 {
    font-size: 14px;
    line-height: 24px;
  }

  .circleImage3 {
    bottom: -30px;
    left: 100px;
    width: 50px;
    height: 50px;
  }
  .imageContainer3 {
    border-radius: 50%;
    border: 6px solid #fff;
    max-width: 100%;
    width: 100%;
  }

  .card3 {
    border-radius: 40px;
    background: #fff;
    box-shadow: 0px 4px 140px 0px rgba(0, 0, 0, 0.05);
    padding: 0;
  }
  .subSection3 {
    border-radius: 40px;
    background: rgba(224, 224, 224, 0.26);
    padding: 0.5em 0.5em;
  }
  .circleImage3 {
    position: absolute;
    bottom: -50px;
    left: 100px;
    width: 73px;
    height: 74px;
    max-width: 100%;
  }
}
