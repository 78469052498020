.__career__paragraph__h6 {
  font-size: x-large;
}
.__career__paragraph {
  font-size: medium;
}

.__career__paragraph__a {
  text-decoration: none;
  color: #e44540;
}
.__career__paragraph__right {
  font-size: medium;
  color: #131313;
}
.title___p {
  font-size: medium;
  color: #131313;
  line-height: 2;
}
.__career__paragraph__p {
  font-size: large;
  font-weight: bold;
  color: #000;
  margin-top: 0;
}
.___small {
  font-size: 13px;
  color: #303030;
  font-style: italic;
}
.__career__paragraph__apply {
  margin-top: 6px;
  color: #e44540;
  font-size: small;
  margin-bottom: 0;
}
.____ha {
  text-decoration: none;
  color: #e44540;
}
.____ha:hover {
  border-bottom: 3px dotted #e44540;
  padding-bottom: 3px;
}

.career__card {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  padding: 0.5em 0.5em;
  border-radius: 8px;
}
