.footerStyle {
  background-color: #eee;
  padding: 30px;
  position: relative;
  margin-top: 3.5em;
}

.paragraphStyle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #000000;
}

.footerImageStyle {
  position: absolute;
  top: -63px;
}
.mgt {
  margin-top: 5em;
  color: #000;
  font-size: 14.8px;
}
.smll {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* .footer__star__image {
  width: 126px;
  height: 127px;
} */
.border__bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.footer__imgStyle1,
._____image {
  width: 130px;
  object-fit: contain;
  height: auto;
}
.footer__a {
  text-decoration: none;
  color: #000;
}
@media (max-width: 767px) {
  .mgt {
    margin-top: 1em;
  }

  .footerImageStyle {
    position: absolute;
    top: -63px;
    right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .footerImageStyle {
    position: absolute;
    top: -63px;
    right: 30px;
  }
}
