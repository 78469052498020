.support___paragraph {
  color: #595959;

  font-size: 17px;
  line-height: 23px;
}

.support___paragraph__h5 {
  color: #595959;
  font-size: 16px;
  line-height: 28px;
}

.support___paragraph__li {
  color: #000;

  font-size: 17px;
  line-height: 28px;
}
.support__span {
  color: #e44540;
  font-size: bold;
}

.a___a {
  color: #e44540;
  text-decoration: none;
}
