.userdata___paragraph {
  color: #595959;

  font-size: 15px;
  line-height: 23px;
}

.user____header__h5 {
  font-size: 25px;
  line-height: 28px;
}

.userdata__span {
  color: #e44540;
  font-size: bold;
}

.a___a {
  color: #e44540;
  text-decoration: none;
}

.userdata___paragraph__li {
  color: #000;

  font-size: 15px;
  line-height: 28px;
}
.email___from {
  padding: 0.5em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.login__border_style {
  border-color: none;
}
.login__border_style:hover {
  border-color: red !important;
}
.login__border_style:focus {
  outline: none !important;
  border-color: red !important;
}
input:focus {
  box-shadow: none !important;
}

.button__style {
  background-color: #e04540;
  outline: none;
  border: none;
}
button:focus {
  box-shadow: none !important;
}

@media (max-width: 767px) {
}
