.cookie___paragraph__h6 {
  color: #000;
  font-size: 19px;
  line-height: 28px;
}
.inner__bold {
  font-weight: bold;
}
.cookie___p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.cookie___address {
  color: #595959;
  font-size: 15px;
  font-style: italic;
}

.cookie___header__p {
  color: #595959;
  font-size: 15px;
  font-weight: bold;
  margin-top: 4rem;
}

.cookie___paragraph__li {
  color: #e44540;
  font-size: 17px;
  line-height: 28px;
}
.a_____style {
  color: #e44540;
  text-decoration: none;
}
