.col__6__bg {
  background-image: url('../assets/VectorBackground.png');
  box-shadow: 0px 4px 140px 0px rgba(0, 0, 0, 0.05);
  height: 400.517px;
  background-size: 100% 100%;
  top: 2.1em;
  position: absolute;
}
.main__2 {
  margin-top: 5em;
  position: relative;
  right: 0;
  left: 0;
}
.img_2 {
  width: 1252px;
  height: 835px;
  object-fit: contain;
  position: absolute;
  right: -1em;
  top: -9em;
}

.st__image {
  width: 100px;
  height: 101px;
  margin-top: 1em;
}
.titleStyle2 {
  color: #e44540;
  font-size: 18.58px;
  font-style: normal;
  font-weight: 400;
}
.paragraphStyle2 {
  color: #000;
  font-size: 16.58px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -40px;
}
.inner__gride {
  border-radius: 22px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  top: 200px;
  right: 100px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.81);
  position: absolute;
}

.badgeImageStyle2 {
  width: 53px;
  height: 53px;
  flex-shrink: 0;
}

.starImage {
  width: 109px;
  height: 109px;
  top: 2em;
  left: 10em;
  position: absolute;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.circleImage2 {
  top: 4em;
  right: 230px;
  width: 70px;
  height: 70px;
  z-index: 1;
  position: absolute;
  animation: bounce 2s ease infinite;
}

@media (max-width: 767px) {
  /* Styles for small devices */

  .main__2 {
    margin-top: 2em;
    margin-bottom: 4em;
    position: relative;
  }

  .col__6__bg {
    height: auto;
    left: 0;
  }

  .img_2 {
    position: relative;
    width: 800px;
    max-width: 100%;
    top: 1.8em;
    right: 0;
    left: -1em;
    height: 350px;
    object-fit: contain;
  }

  .inner__gride {
    position: static;
    top: auto;
    right: auto;
  }

  .circleImage2 {
    top: 8em;
    right: 3em;
    width: 50px;
    height: 50px;
    animation: bounce 2s ease infinite;
  }

  .starImage {
    width: 70px;
    height: 70px;
    top: 2.6em;
    left: 8.5em;
    position: absolute;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .col__6__bg {
    background-image: url('../assets/VectorBackground.png');
    box-shadow: 0px 4px 140px 0px rgba(0, 0, 0, 0.05);
    height: 280px;
    background-size: 100% 100%;
    top: 7.3em;
    position: absolute;
  }
  .main__2 {
    position: relative;
    margin-top: -0.7em;
    right: 0;
    left: 0;
  }
  .img_2 {
    width: 690px;
    height: 800px;
    object-fit: contain;
    position: absolute;
    right: -0.009em;
    top: -5.4em;
  }
  .img___2 {
    width: 100%;
    height: 100%;
  }

  .circleImage2 {
    top: 7.5em;
    right: 120px;
    width: 50px;
    height: 50px;
    animation: bounce 2s ease infinite;
  }

  .st__image {
    width: 100px;
    height: 101px;
    margin-top: 1em;
  }
  .titleStyle2 {
    color: #e44540;
    font-size: 16.58px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
  }
  .paragraphStyle2 {
    color: #000;
    font-size: 16.58px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -40px;
  }
  .inner__gride {
    border-radius: 22px;
    opacity: 0.81;
    border: 1px solid rgba(0, 0, 0, 0.4);
    top: 3.5em;
    right: 1.3em;
    z-index: 1;
    position: absolute;
  }

  .badgeImageStyle2 {
    width: 53px;
    height: 53px;
    flex-shrink: 0;
  }

  .starImage {
    width: 70px;
    height: 70px;
    top: 2.5em;
    left: 8em;
    position: absolute;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .col__6__bg {
    background-image: url('../assets/VectorBackground.png');
    box-shadow: 0px 4px 140px 0px rgba(0, 0, 0, 0.05);
    height: 280px;
    background-size: 100% 100%;
    top: 7.3em;
    position: absolute;
  }
  .main__2 {
    position: relative;
    margin-top: -0.7em;
    right: 0;
    left: 0;
  }
  .img_2 {
    width: 720px;
    height: 800px;
    object-fit: contain;
    position: absolute;
    right: 1em;
    top: -3.1em;
  }
  .img___2 {
    width: 100%;
    height: 100%;
  }

  .circleImage2 {
    top: 9.9em;
    right: 120px;
    width: 60px;
    height: 60px;
    animation: bounce 2s ease infinite;
  }

  .st__image {
    width: 100px;
    height: 101px;
    margin-top: 1em;
  }
  .titleStyle2 {
    color: #e44540;
    font-size: 16.58px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
  }
  .paragraphStyle2 {
    color: #000;
    font-size: 16.58px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -40px;
  }
  .inner__gride {
    border-radius: 22px;
    opacity: 0.81;
    border: 1px solid rgba(0, 0, 0, 0.4);
    top: 3.5em;
    right: 1.3em;
    z-index: 1;
    position: absolute;
  }

  .badgeImageStyle2 {
    width: 53px;
    height: 53px;
    flex-shrink: 0;
  }

  .starImage {
    width: 70px;
    height: 70px;
    top: 2.5em;
    left: 9em;
    position: absolute;
  }
}
