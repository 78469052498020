.whatsAppButton__style {
  position: fixed;
  bottom: 50px;
  right: 110px;
  cursor: pointer;
  z-index: 1000;
}
.whatsAppButton__image__style {
  border-radius: 50%;
  object-fit: contain;
  max-width: 100%;
  width: 60px;
  height: auto;
}

.tooltip {
  visibility: visible;
  width: 200px;
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  padding: 8px 1px;
  position: absolute;
  z-index: 1;
  top: calc(50% - 60%); 
  left: 50%;
  transform: translate(-50%, -100%);
  opacity: 1;
  transition: opacity 0.3s;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  text-transform: capitalize;
}

.tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #ffffff; 
  border-bottom-color: #ffffff; 
 
}

 

@media (max-width: 768px) {
  .whatsAppButton__style {
    position: fixed;
    bottom: 30px;
    right: 75px;
    cursor: pointer;
    z-index: 1000;
  }
  .whatsAppButton__image__style {
    border-radius: 50%;
    object-fit: contain;
    max-width: 100%;
    width: 60px;
    height: auto;
  }
}

