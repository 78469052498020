.share__component__title__h2 {
  color: #e44540;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  margin-top: 2em;
}

.___bg {
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 6px 140px 0px rgba(0, 0, 0, 0.05);
  padding: 35px 20px;
  margin: 1em 0 2em 0;
}

.___bg:hover {
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 6px 140px 0px rgba(0, 0, 0, 0.05);
  padding: 35px 20px;
  margin: 1em 0 2em 0;
  border: 3px dotted #e44540;
}
.share__component__title__p {
  color: #003440;
  font-size: 22px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 17px;
  margin-top: -15px;
}

.share__component__title__subtitle__icon {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 25px;
  border-radius: 50%;
  background-color: #fef2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share__component__title__subtitle__image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 1em;
}
.____icon {
  font-size: 35px;
  color: #ea1e00;
}

.share__component__title__subtitle {
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  margin-top: -10px;
}
.share__component__title__paragraph {
  color: #595959;
  font-size: 16px;
  line-height: 35px;
  margin-top: -10px;
}

@media only screen and (max-width: 768px) {
  .share__component__title__h2 {
    font-size: 25px;
    margin-top: 2em;
  }

  .___bg {
    padding: 25px 15px;
    margin: 0.5em 0 1em 0;
  }

  .share__component__title__p {
    font-size: 18px;
    padding-top: 8px;
    padding-bottom: 15px;
    margin-top: -10px;
  }

  .____icon {
    font-size: 30px;
  }

  .share__component__title__subtitle {
    font-size: 17px;
    margin-top: -8px;
  }

  .share__component__title__paragraph {
    font-size: 16px;
    line-height: 30px;
    margin-top: -8px;
  }
}
