.headerStyle4 {
  color: #000;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 3em;
}

.st__image4 {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.bgf4 {
  width: 200px;
  height: 200px;
  background-color: #e44540;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 1rem;
}

.imageStyle4 {
  width: 254px;
  height: 354px;
  object-fit: contain;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: -60px;
  padding-bottom: 0;
}

/* Title Style */
.titleStyle4 {
  color: #e44540;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.paragraph4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #303030;
}

@media (max-width: 767px) {
  .headerStyle4 {
    font-size: 30px;
  }

  .bgf4 {
    width: 150px;
    height: 150px;
    margin-top: 0.3rem;
  }

  .imageStyle4 {
    width: 140px;
    height: 130px;
  }

  .titleStyle4 {
    font-size: 20px;
    margin-top: 0em;
  }

  .paragraph4 {
    font-size: 16px;
    line-height: 26px;
  }

  .st__image4 {
    width: 70px;
    height: 80px;
    object-fit: contain;
  }
}
