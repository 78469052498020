.FirstSectionStyle1 {
  color: #e44540;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1em;
}

.FirstSectionStyle_paragraph1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 51.2px;
  color: #000000;
  margin-bottom: 4px;
}

.smallText1 {
  color: #797979;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.imgStyle__1 {
  width: 150px !important;
  height: 60px;
  object-fit: contain;
  max-width: 100%;
}

.grid1 {
  z-index: 1;
}

@media (max-width: 767px) {
  .FirstSectionStyle1 {
    font-size: 48px;
  }

  .FirstSectionStyle_paragraph1 {
    font-size: 24px;
    line-height: 32px;
  }

  .smallText1 {
    font-size: 14px;
  }

  .imgStyle1 {
    width: 150px;
    height: 60px;
    object-fit: contain;
    max-width: 100%;
  }
}

@keyframes bounceOnce {
  20%,
  50%,
  80% {
    transform: translateY(-20px);
  }
  40%,
  60% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
