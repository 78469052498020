/* Menubar Style */
.menubarStyle {
  border: none;
  background-color: #fff;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
  color: #000;
  line-height: normal;
  outline: none;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1.25rem;
  color: #4b5563;
  border-radius: 6px;
  transition: box-shadow 0.2s;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
}
.p-menuitem-link:hover {
  background-color: #fff;
}
.p-menuitem-link:active {
  background-color: #fff;
  outline: none;
}
.p-menuitem-link > a:focus {
  box-shadow: none;
}
.p-menubar {
  padding: 0.5rem;
  background: #fff;
  color: #4b5563;
  border: 0 solid #fff;
  border-radius: 6px;
}
/* Button Style */
.buttonStylef {
  background-color: #ffca02;
  border: none;
  color: black;
  padding: 14px 28px;
  text-align: center;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12.53px;
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 400;
  outline: none;
}
.___header___logo {
  object-fit: contain;
  width: 151px !important;
  height: 32.51px !important;
  margin-top: -.8em !important;
}

@media (max-width: 768px) {
  .buttonStylef {
    padding: 10px 15px;
    font-size: 15px;
    border-radius: 10px;
  }
}
